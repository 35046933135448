var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: {
        showSearch: true,
        filterOption: false,
        allowClear: true,
        loading: _vm.branchOption.fetching,
        disabled: _vm.disabled,
        dropdownMatchSelectWidth: false,
        value: _vm.value
      },
      on: { search: _vm.searchBranch, change: _vm.handleChange }
    },
    _vm._l(_vm.branchOption.data, function(item, index) {
      return _c(
        "a-select-option",
        {
          key: index,
          attrs: { value: item.id },
          on: {
            click: function($event) {
              return _vm.onMeta(item)
            }
          }
        },
        [
          _c(
            "a-tooltip",
            [
              _c("template", { slot: "title" }, [
                _vm._v(" " + _vm._s(item.name) + " ")
              ]),
              _vm._v(" " + _vm._s(item.name) + " ")
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }