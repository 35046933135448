



























/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { debounce } from "@/helpers/debounce";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { logisticServices } from "@/services/logistic.service";
import { Component, Vue } from "vue-property-decorator";

const BranchSelectProps = Vue.extend({
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
});

/**
 * @deprecated
 * new component {@link SelectBranch}
 */
@Component
export default class BranchSelect extends BranchSelectProps {
  branchOption = {
    data: [] as DataWarehouseBranch[],
    search: "",
    fetching: true,
    initialData: [] as DataWarehouseBranch[],
  };

  async created() {
    await this.getBranchList(true);
    if (this.value && !this.branchOption.data.find(c => c.id === this.value)) {
      this.fetchMoreBranch();
    }
  }

  onMeta(option: DataWarehouseBranch): void {
    this.$emit("meta", { meta: option });
  }

  async getBranchList(firstLoad = false) {
    try {
      this.branchOption.fetching = true;
      const param: RequestQueryParamsModel = {
        limit: 20,
        page: 0,
        search: ``,
      };
      if (this.branchOption.search) {
        param.search = `name~*${this.branchOption.search}*`;
      }
      const res = await logisticServices.listWarehouseBranch(param, "");
      this.branchOption.data = res.data;
      this.$emit("on-getListBranch", res.data);
      if (firstLoad) {
        this.branchOption.initialData = res.data;
      }
      this.branchOption.fetching = false;
    } catch (error) {
      this.branchOption.fetching = false;
    }
  }

  searchBranch(value: string): void {
    debounce(() => {
      this.branchOption.search = value;
      this.getBranchList();
    });
  }

  handleChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
    this.resetOptionList();
  }

  findOption(val: string): DataWarehouseBranch | undefined {
    return this.branchOption.data.find(item => item.id === val);
  }

  resetOptionList(): void {
    this.branchOption.data = this.branchOption.initialData;
  }

  /**
   * Fetch branch list from API until matching with @this value
   */
  async fetchMoreBranch() {
    try {
      this.branchOption.fetching = true;
      let totalPage = 1;
      const param = {
        limit: 20,
        page: 1,
      };
      while (
        !this.branchOption.data.find(c => c.id === this.value) &&
        param.page <= totalPage
      ) {
        const branchOptions = await logisticServices.listWarehouseBranch(
          param,
          ""
        );
        totalPage = Math.ceil(branchOptions.totalElements / 20);
        this.branchOption.data = this.branchOption.data.concat(
          branchOptions.data
        );
        param.page++;
      }
      this.$emit("on-getListBranch", this.branchOption.data);
      this.branchOption.fetching = false;
    } catch (error) {
      this.branchOption.fetching = false;
    }
  }
}
